import React from "react"
import { Container, Box, Typography } from "@mui/material"

import Title from "../../components/Title"
import Footer from "../../components/Footer"

export default () => {
  return (
    <div className="App">
      <Title
        title="SALARY NEGOTIATIONS"
        subTitle="How to negotiate for a higher salary?"
      />
      <Container maxWidth="xl">
        <Box mb={3}>
          <Typography variant="body2">
            Salary negotiation is a skill every jobseeker should learn before
            they enter their first job. It should justify the responsibility
            they are going to deliver. it should be fair and as per industry
            standards
          </Typography>
          <Typography
            variant="h5"
            sx={{
              fontSize: "21px",
              color: "#091188",
              fontWeight: "600",
              marginTop: 3,
              marginBottom: 2,
            }}
          >
            The 1<sup>st</sup> Secret of Salary negotiation
          </Typography>
          <Typography variant="body2">
            Never discuss salary until the end of the whole interview process at
            the organization. Yes, you should do your research if the company
            has the budget to hire you. The end of the interview process is a
            stage where the employer decides that you are the person whom they
            want to hire. But incase this question comes early in the interview
            few tactful answers could be
          </Typography>
          <Typography variant="body2">
            "I would be able to answer this question, but could you first help
            me understand what this role involves Secondly" you can tell them a
            range, I am open for a salary based on my KRA in this range"
          </Typography>

          <Typography variant="body2">
            Or in case you are in desperate need of the job, Ask them what their
            range they have in mind and make a decision. Of course ask for some
            time before you decide.
          </Typography>
          <Typography
            variant="h5"
            sx={{
              fontSize: "21px",
              color: "#091188",
              fontWeight: "600",
              marginTop: 3,
              marginBottom: 2,
            }}
          >
            The 2<sup>nd</sup> secret of Salary negotiation{" "}
          </Typography>

          <Typography variant="body2">
            The second secret of Salary negotiation is to uncover the most that
            an employer is willing to pay. There are very few employers who fix
            the salary for a job and offer the same on selection. Most employers
            have a range and they use the bidding method . They want to hire
            somebody at, let's say in the range of 10-15 LPA, they would want
            the person to come on 10 LPA and you as a job seeker should try to
            bring the maximum cash home. Nothing is wrong either at the employer
            or the job hunter side, this is all a game of negotiation. Once you
            know the lower limit of the Salary, you can easily take it 10-20% up
            with little effort. Keep a strong justification ready, why you
            deserve this Salary.
          </Typography>

          <Typography
            variant="h5"
            sx={{
              fontSize: "21px",
              color: "#091188",
              fontWeight: "600",
              marginTop: 3,
              marginBottom: 2,
            }}
          >
            The 3<sup>rd</sup> secret of Salary negotiation{" "}
          </Typography>
          <Typography variant="body2">
            During Salary discussion, never be the first one to mention a salary
            figure. Inexperienced recruiters often make the mistake of telling
            the budget to the jobseekers and that makes the employer lose the
            game. Its thumb rule, whosoever shared their salary range, have lost
            too much in salary negotiation. So a recruiter may ask you - What
            kind of Salary are you looking for? And you can make a counter move
            by saying, "well you created the position, so you must have some
            figure in mind" and I'd be interested to first hear what that figure
            is. But this is not the end, experienced recruiters know a lot of
            ways to hide the information, and they may coax you to blurt out
            your expectations.
          </Typography>
          <Typography
            variant="h5"
            sx={{
              fontSize: "21px",
              color: "#091188",
              fontWeight: "600",
              marginTop: 3,
              marginBottom: 2,
            }}
          >
            The 4<sup>th</sup> secret of Salary Negotiations{" "}
          </Typography>

          <Typography variant="body2">
            Before you get to the interview, do some careful research on typical
            salaries for your field and in that organization. Research online on
            platforms like Glassdoor, Ambition box, speak to people who have
            worked in the same role in other companies. If the employer you are
            interviewing with has multiple people in a similar role, you can try
            to extract information. Try to understand the market standard, match
            it with your experience and skill sets and keep a range ready in
            your mind. Having realistic expectations is necessary to negotiate
            well. There are so many novice job seekers who want a certain
            package because some ABC colleague or friend of theirs has got such
            a package. It never works in your favor, if your reason for salary
            hike is based on somebody's else's package. While it may hurt your
            ego, but there could be a possibility you are not as skilled as that
            friend of yours. And apart from that there are various parameters on
            which a salary is decided, so keep your reasonings strong.
          </Typography>
          <Typography
            variant="h5"
            sx={{
              fontSize: "21px",
              color: "#091188",
              fontWeight: "600",
              marginTop: 3,
              marginBottom: 2,
            }}
          >
            5<sup>th</sup> Secret of Salary negotiation{" "}
          </Typography>
          <Typography variant="body2">
            Salary discussion with this employer is not finished until you've
            addressed more than salary. You should discuss the salary structure,
            other benefits you might be getting. Companies go to a good extent
            if they like anyone for the role, and they see the potential in that
            hire. There have been times when jobseekers have got much more perks
            than the actual salary.
          </Typography>

          <Typography variant="body2">
            Once all is done, you have liked the employer, the employer has
            decided to hire you, and Salary and other benefits have been
            decided. Ask for the offer in writing. go through it well and maybe
            you would want the offer to be vetted by somebody before you accept
            the offer. Ensure there is no discrepancy in what you are offered
            and what comes in writing.
          </Typography>

          <Typography variant="body2">
            Negotiation in itself is an art, where psychology to economy to
            business sense everything comes into play mixed with your own
            experience. And a good negotiation is such, where both parties feel
            they won. While you negotiate there is a thin line between acting
            greedy and asking for a fair package. Never cross that line, else
            the same employer who wanted to hire you, will double check their
            intention to take you onboard. A Job is not only about Salary, there
            should be something more which attracts you to the job. Evaluate any
            such reason too while you negotiate.
          </Typography>
        </Box>
      </Container>
      <Footer />
    </div>
  )
}
